import {customElement} from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import {sbkSearchSuggestionItem} from "@/components/search-suggestion-item/search-suggestion-item.styles";


@customElement('sbk-search-suggestion-item')
export class SbkNavFlyoutItem extends LitElement {

    static get styles() {
        return sbkSearchSuggestionItem;
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div class="search-suggestion-item">
                <slot></slot>
            </div>
        `;
    }
}
